import * as React from "react"
import Header from "../components/layouts/Header"
import "../assets/css/global.scss";
import techBanner from "../assets/img/tecnology.svg";

import Banner from "../components/banner";
import TechnologiesJson from "../data/technology.json";

import Java from "../assets/icons/java.svg";
import Layouts from "../components/layouts";

import Technologies from "../components/Technologies";
import Aos from "aos";
import "aos/dist/aos.css";

// markup
const IndexPage = () => {
    React.useEffect(function () {
        Aos.init({ duration: 1000 });
      }, []);
    return (
        <>
            <Layouts>

                <Banner bannerVector={techBanner}>
                    <h2>Technologies</h2>
                    <p className="banner-para">Technology for Transformation   <span>Frameworks and platforms to drive changes for humane ease of living</span>
                    </p>
                    
                </Banner>
                {/* .container
            <div className="page-section">
                <div className="container">
                    <div className="text-center wow fadeInUp">
                        <h2 className="title-section">Web Application</h2>
                        <div className="divider mx-auto" />
                    </div>
                    <div className="row mt-5 text-center">
                        {Data.web.map((elem, key) => (
                            <div className="col-md-3 py-3 wow fadeInUp " key={key}>
                                <div className="display-3 card-img">
                                    <img className="card-img1" src={elem.image} alt={elem.name} />
                                </div>
                                <h5>{elem.name}</h5>
                            </div>))}
                    </div>
                </div>
            </div>
             */}
                <div className="padding container " >
                   <Technologies data={TechnologiesJson} />
                </div>

            </Layouts>
        </>
    )
}

export default IndexPage
