import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

export default function ServiceBody({ data }) {

    useEffect(function () {
        Aos.init({ duration: 1000 });
    }, []);

    return (
        <>
            <section className="agency_featured_area-1 sec_pad page-section-top">
                <div className="container">
                    <h2 className="text-center">Process We Follow</h2>
                    <div class="divider mx-auto"></div>
                    <div className="features_info">
                        <img className="dot_img " src="https://static.hyperlinkinfosystem.co.uk/frontend/img/home4/dot.png" alt="process dot" data-pagespeed-url-hash={375519320} onload="pagespeed.CriticalImages.checkImageForCriticality(this);" />
                        <div className="row agency_featured_item agency_featured_item_one flex-row-reverse">
                            <div className="col-lg-6 col-12 process-img-align image-1">
                                <div className="agency_featured_img text-right">
                                 <img src="/icons/p-1.svg" className="proces-flow-img"/>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="agency_featured_content pr_70 pl_70">
                                    <div className="dot"><span className="dot1" /><span className="dot2" /></div>
                                    <div className="number-tb">1</div>
                                    <h3>Requirement Gathering</h3>
                                    <p>We analyze the requirements with the clients to understand the functionalities to be integrated into the app. This process enables us to draw a development plan and transform the clients’ concepts into an efficient and
                                        functional app.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row agency_featured_item agency_featured_item_two process-1">
                            <div className="col-lg-6 col-12 process-img-align">
                                <div className="agency_featured_img text-right">
                                <img src="/icons/p-2.svg" className="proces-flow-img"/>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="agency_featured_content pr_70 pl_70 flow-content">
                                    <div className="dot-position2"><span className="dot1" /><span className="dot2" /></div>
                                    <div className="number-tb">2</div>
                                    <h3 className="ui-ux-process">UI/UX Design</h3>
                                    <p>Our developers use effective UI trends to design apps that are not only pleasing to the eye but also intuitiveness and consistency. Our applications do not only satisfy the needs of our clients but are also simple and convenient
                                        for the end-users.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row agency_featured_item agency_featured_item_three flex-row-reverse">
                            <div className="col-lg-6 col-12 process-img-align image-1">
                                <div className="agency_featured_img text-right">
                                <img src="/icons/p-3.svg" className="proces-flow-img"/>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="agency_featured_content pr_70 pl_70">
                                    <div className="dot-position3"><span className="dot1" /><span className="dot2" /></div>
                                    <div className="number-tb">3</div>
                                    <h3>Prototype</h3>
                                    <p>We develop a preliminary visualization of what the mobile app would look like. This generates an idea of the look and feel of the app, and we test the users’ reactions to the UI and UX designs.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row agency_featured_item agency_featured_item_four">
                            <div className="col-lg-6 col-12 process-img-align">
                                <div className="agency_featured_img text-right">
                                <img src="/icons/p-4.svg" className="proces-flow-img"/>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 flow-content4">
                                <div className="agency_featured_content pr_70 pl_70">
                                    <div className="dot-position4"><span className="dot1" /><span className="dot2" /></div>
                                    <div className="number-tb">4</div>
                                    <h3>App Development</h3>
                                    <p>We have a team of experts in Native, Hybrid and Cross-Platform app development, using languages such as Swift, Kotlin, PhoneGap, Ionic, Xamarin, and so on to develop high-quality mobile apps for the different operating
                                        systems.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="row agency_featured_item agency_featured_item_five flex-row-reverse">
                            <div className="col-lg-6 col-12 process-img-align image-5">
                                <div className="agency_featured_img text-right">
                                <img src="/icons/p-5.svg" className="proces-flow-img"/>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12">
                                <div className="agency_featured_content q_assurnce pr_70 pl_70">
                                    <div className="dot-position5"><span className="dot1" /><span className="dot2" /></div>
                                    <div className="number-tb">5</div>
                                    <h3>Quality Assurance</h3>
                                    <p>Our developers carefully test every app to ensure that they provide excellent user experience and meet the requirements of our clients. Apps developed by our development team are bug-free because they go through a series
                                        of tests before deployment.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row agency_featured_item agency_featured_item_six">
                            <div className="col-lg-6 col-12 process-img-align">
                                <div className="agency_featured_img text-right">
                                <img src="/icons/p-6.svg" className="proces-flow-img"/>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 flow-content6">
                                <div className="agency_featured_content pr_70 pl_70">
                                    <div className="dot-position6"><span className="dot1" /><span className="dot2" /></div>
                                    <div className="number-tb">6</div>
                                    <h3>Deployment</h3>
                                    <p>We follow the best practices when deploying our apps on various app stores where they can be easily visible to prospective users.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row agency_featured_item agency_featured_item_seven flex-row-reverse row7">
                            <div className="col-lg-6 col-12 process-img-align image-5 ">
                                <div className="agency_featured_img text-right">
                                <img src="/icons/p-7.svg" className="proces-flow-img"/>
                                </div>
                            </div>
                            <div className="col-lg-6 col-12 flow-content7">
                                <div className="agency_featured_content pr_70 pl_70">
                                    <div className="dot-position7"><span className="dot1" /><span className="dot2" /></div>
                                    <div className="number-tb">7</div>
                                    <h3>Support &amp; Maintenance</h3>
                                    <p>All digital solutions need improvement. The deployment of an app is not the final stage. We work with our clients to offer post-deployment maintenance and support.</p>
                                </div>
                            </div>
                        </div>
                        <div className=" middle_dot"><span className="dot1" /><span className="dot2" /></div>
                    </div>
                </div>
                
            </section>
            {data.map((item, key) => (
                <div data-aos="fade-up" className="">
                    <h2 className="title">{item.title}</h2>
                    <div class="divider  mx-auto"></div>
                    <div className="row card1">
                        {item.technologies.map((elem, key) => (
                            <div key={key} >
                                <img src={elem.image} alt={elem.name} className="card-img" />
                                <span className="h3">{elem.name}</span>
                            </div>))}

                    </div>
                </div>
            ))}
        </>
    )
}